import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import "./text-field.scss";
import PhoneInput from "react-phone-number-input/input";

export default forwardRef(function TextField(
    {
        type = "text",
        name,
        error,
        isFocused = false,
        leftIcon,
        rightIcon,
        rightIcon2,
        translateError = true,
        ...props
    }: any & {
        error?: string;
        isFocused?: boolean;
        leftIcon?: string;
        rightIcon?: string;
        rightIcon2?: string;
        type?: string;
        name: string;
        translateError?: boolean;
    },
    ref,
) {
    const localRef = useRef<HTMLInputElement>(null);

    const [showPassword, updateShowPassword] = useState(false);
    const [inputType, setInputType] = useState("text");
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    useImperativeHandle(ref, () => ({
        focus: () => localRef.current?.focus(),
    }));

    useEffect(() => {
        if (isFocused) {
            localRef.current?.focus();
        }
        if (type === "date") {
            setInputType("text");
        } else setInputType(type);
    }, []);

    const renderInput = () => {
        if (type === "tel") {
            return (
                <PhoneInput
                    {...props}
                    name={name}
                    country="US"
                    ref={localRef}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            );
        }
        return (
            <input
                {...props}
                type={
                    inputType === "password"
                        ? showPassword
                            ? "text"
                            : "password"
                        : inputType
                }
                name={name}
                ref={localRef}
                onFocus={() => {
                    if (type === "date") {
                        setInputType("date");
                    }
                    onFocus();
                }}
                onBlur={onBlur}
            />
        );
    };

    return (
        <>
            <div
                className={`input-field hide-calendar-icon ${error ? "error" : focused ? "focused" : ""}`}
            >
                {leftIcon ? leftIcon : null}
                {renderInput()}
                {rightIcon ? (
                    <img
                        src={
                            inputType === "password"
                                ? showPassword
                                    ? rightIcon2
                                    : rightIcon
                                : inputType
                        }
                        alt={`${name}-right-icon`}
                        className="right-icon show"
                        onClick={(_e: React.MouseEvent<HTMLElement>) => {
                            updateShowPassword(!showPassword);
                        }}
                    />
                ) : null}
            </div>

            <p
                className={`input-error ${translateError ? "translate" : "notranslate"}`}
            >
                {error}
            </p>
        </>
    );
});
